import * as colors from './colors';
import fonts from './fonts';
import media from './media';
import styles from './styles';

const commonThemeColors = {
  black: colors.JungleGreenDark,
  white: colors.White,
  darkGrey: colors.RiverBed,
  transparent13: colors.Transparent13,
  error: colors.RedError,
  textMuted: colors.BattleshipGrey,
  veryLightGreyForBg: colors.Transparent03,
  highlight: colors.MediumPink,
  orange: colors.Orange,
  inputBorder: colors.InputBorder,
  greyText: colors.GreyText,
  successGreen: colors.MediumGreen,
  blueCardBackground: `linear-gradient(204deg, ${colors.OceanBlue} -0.76%, ${colors.SkyBlue} 44.59%, ${colors.Bblue} 141%)`,
  bronzeCardBackground: `linear-gradient(204deg, ${colors.BrownBear} -0.76%, ${colors.ClayBrown} 44.59%, ${colors.MilkChocolate} 141%)`,
  silverCardBackground: `linear-gradient(203.94deg, ${colors.CloudyGrey} -0.76%, ${colors.SilverChalice} 44.59%, ${colors.VampireGrey} 141%)`,
  platinumCardBackground: `linear-gradient(203.94deg, ${colors.Cloud} -0.76%, ${colors.SatinLinen} 44.59%, ${colors.WarmGrey} 141%)`,
  platinumCardText: colors.RiverBed,
  goldCardBackground: `linear-gradient(203.94deg, ${colors.Hazel} -0.76%, ${colors.Turmeric} 44.59%, ${colors.YellowyBrown} 141%);`
};
const themeColors = {
  light: {
    paymentDetailsCardBackground: colors.CardGreyBackground,
    background: colors.White,
    textPrimary: colors.Dark,
    ...commonThemeColors
  },
  dark: {
    paymentDetailsCardBackground: colors.CardGreyBackground,
    background: colors.Dark,
    textPrimary: colors.White,
    ...commonThemeColors
  }
};

export { colors, themeColors, fonts, media, styles };
