import styled from 'styled-components';
import { Translation } from '@app/modules/common/index';

export const StyledTitleContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 90%;
  align-self: center;
  margin-top: 1rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
`;

export const Title = styled(Translation)`
  color: ${(props) => props.theme.white};
  font-size: 1.6rem;
  font-weight: 600;
`;

export const Detail = styled(Translation)`
  color: ${(props) => props.theme.white};
  font-size: 1.2rem;
  font-weight: 500;
`;

export const Description = styled(Translation)`
  color: ${(props) => props.theme.white};
  font-size: 1.2rem;
  font-weight: 200;
  text-align: justify;
`;
