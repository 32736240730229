export default {
  deleteAccount: {
    route: '/delete',
    exact: true,
    navbar: false
  },
  emailVerification: {
    route: '/verify',
    exact: true,
    navbar: false
  },
  privacyPolicy: {
    route: '/privacy',
    exact: true,
    navbar: false
  },
  classDetails: {
    route: '/classDetails',
    exact: true,
    navbar: false
  },
  videoDetails: {
    route: '/videoDetails',
    exact: true,
    navbar: false
  },
  teacherDetails: {
    route: '/teacherDetails',
    exact: true,
    navbar: false
  },
  loginSignUp: {
    route: '/',
    exact: true,
    navbar: false
  },
  dashboard: {
    route: '/dashboard',
    exact: true,
    isProtected: true,
    navbar: true
  },
  subscription: {
    route: '/subscription',
    isProtected: true,
    navbar: true
  },
  download: {
    route: '/download',
    exact: true,
    navbar: false
  },
  payment: {
    route: '/payment',
    isProtected: true,
    navbar: false
  },
  paymentSuccess: {
    route: '/paymentSuccess',
    isProtected: true,
    navbar: false
    // exact: true
  }
};
