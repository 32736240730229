import React, { memo, useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
  selectLoading,
  selectPlans,
  selectUserSubscription,
  selectUserCardDetails,
  selectUserCardDetailsLoading,
  selectUserProfile,
  selectCouponLoading,
  selectCouponError,
  selectCoupon,
  selectRetryPaymentInitiated,
  selectDisciplines,
  selectUserPlanDescription
} from './selectors';
import { subscriptionCreators } from './reducer';
import { connect } from 'react-redux';
import subscriptionSaga from './saga';
import { injectIntl } from 'react-intl';
import { injectSaga } from 'redux-injectors';

import NoSubscriptionCard from './components/NoSubscriptionCard/NoSubscriptionCard';
import { logToCrashalytics, isClassPass } from 'tatvarnsdk/Web';
import { Divider, StyledSubscription, SubscriptionContainer } from './Subscription.styles';
import SavedCard from './components/SavedCard/SavedCard';
import { Loading, Translation } from '../common/index';
import UpgradeSubscriptionCard from './components/UpgradeSubscriptionCard/UpgradeSubscriptionCard';
import { mySubscriptionNoPlans } from '@app/themes/images';
import SubscriptionCard from './components/SubscriptionCard/SubscriptionCard';
import PausedSubscriptionCard from './components/pausedCard/PausedSubscriptionCard';
import CouponView from '../CouponView/CouponView';

function Subscription(props) {
  const {
    dispatchDetails,
    dispatchResetKeys,
    dispatchChangeDefaultCard,
    dispatchAddCardRequest,
    dispatchDeleteCard,
    coupon,
    loading,
    planData,
    userProfile,
    couponLoading,
    userCardDetails,
    userCardDetailsLoading,
    userSubscription,
    dispatchRetryCard,
    userDocId,
    setuserDocId,
    dispatchDeleteDocTokenSub,
    retryPaymentInitiated,
    disciplineList,
    userPlanDescription
  } = props;
  const [isOpen, setIsOpen] = useState(props.location.state && props.location.state.isOpen ? true : false);
  let buttonTextId = userSubscription ? 'upgrade' : 'subscribe';
  const upgradeSubscriptionHeading = userSubscription ? 'Upgrade Subscription' : 'Subscribe';
  const addNewCard = (newCardDetails) => {
    dispatchAddCardRequest(newCardDetails, userProfile.stripeCustomerId);
  };
  const changeDefaultCard = (cardId) => {
    dispatchChangeDefaultCard(cardId, userProfile.stripeCustomerId);
  };
  const deleteCard = (cardId) => {
    dispatchDeleteCard(cardId, userProfile.stripeCustomerId);
  };
  const retryCard = (cardId) => {
    let alert = null;
    if (props.location.state && props.location.state.userAlert) {
      alert = props.location.state.userAlert;
    }
    dispatchRetryCard(cardId, userProfile.stripeCustomerId, alert);
  };
  useEffect(() => {
    logToCrashalytics('Subscription');
    if (userDocId) {
      dispatchDeleteDocTokenSub(userDocId);
      setuserDocId(null);
    }
    const state = props.history.location.state?.revertToPreviousPath || false;
    if (!state) {
      dispatchResetKeys();
      dispatchDetails();
    }
  }, []);
  useEffect(() => {
    logToCrashalytics('Subscription Container');
  }, []);

  return (
    <StyledSubscription>
      <SubscriptionContainer userCardDetailsLength={userCardDetails.length} userSubscription={userSubscription}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="details">
              <div className="currentSubscription">
                <div className="heading">
                  <Translation id="MY_SUBSCRIPTION" />
                </div>
                <div className="userPlan">
                  {!userSubscription ? (
                    <NoSubscriptionCard />
                  ) : userSubscription.status === 'paused' ? (
                    <PausedSubscriptionCard plan={userSubscription} type={'PAUSED_SUBSCRIPTION'} />
                  ) : (
                    <SubscriptionCard
                      plan={userSubscription}
                      userSubscription={userSubscription}
                      isLoading={userCardDetailsLoading}
                      savedCards={userCardDetails ? userCardDetails : []}
                      changeDefaultCard={changeDefaultCard}
                      isOpen={isOpen}
                      setIsOpen={setIsOpen}
                      type={'CURRENT_SUBSCRIPTION'}
                      retryCard={retryCard}
                      retryPaymentInitiated={retryPaymentInitiated}
                      userPlanDescription={userPlanDescription}
                    />
                  )}
                </div>
              </div>
              <div className="paymentMethod">
                <div className="heading">
                  <Translation id="PAYMENT_METHOD" />
                </div>
                <SavedCard
                  isLoading={userCardDetailsLoading}
                  savedCards={userCardDetails ? userCardDetails : []}
                  changeDefaultCard={changeDefaultCard}
                  deleteCard={deleteCard}
                  addNewCard={addNewCard}
                />
              </div>
            </div>
            <div className="upgradeSubscription">
              {planData.length == 0 ? (
                <div className="illustration">
                  <img src={mySubscriptionNoPlans} />
                </div>
              ) : (
                <>
                  <div className="heading">
                    <Translation text={upgradeSubscriptionHeading} />
                  </div>
                  <div className="sub-heading">
                    <Translation id="PLAN_SALUTATION" />
                  </div>
                  <div className="upgradeSubscriptionBody">
                    <div
                      className="plans"
                      style={{ width: planData && planData.length === 5 ? '100%' : 'fit-content' }}
                    >
                      {couponLoading ? (
                        <Loading />
                      ) : (
                        planData.map((plan, index) => {
                          buttonTextId = isClassPass(plan) ? 'buy' : buttonTextId;
                          return (
                            <UpgradeSubscriptionCard
                              disciplineList={disciplineList}
                              plan={plan}
                              userProfile={userProfile}
                              userSubscription={userSubscription}
                              buttonTextId={buttonTextId}
                              userCardDetails={userCardDetails ? userCardDetails : []}
                              key={index}
                              coupon={coupon}
                            />
                          );
                        })
                      )}
                    </div>
                    <Divider />
                    <CouponView />
                    <Divider />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </SubscriptionContainer>
    </StyledSubscription>
  );
}

const mapStateToProps = createStructuredSelector({
  planData: selectPlans(),
  loading: selectLoading(),
  userSubscription: selectUserSubscription(),
  userCardDetails: selectUserCardDetails(),
  userCardDetailsLoading: selectUserCardDetailsLoading(),
  userProfile: selectUserProfile(),
  coupon: selectCoupon(),
  couponLoading: selectCouponLoading(),
  couponError: selectCouponError(),
  disciplineList: selectDisciplines(),
  retryPaymentInitiated: selectRetryPaymentInitiated(),
  userPlanDescription: selectUserPlanDescription()
});

export function mapDispatchToProps(dispatch) {
  const {
    requestDetails,
    resetKeys,
    applyCoupon,
    resetCoupon,
    changeDefaultCard,
    addCardRequest,
    deleteCardRequest,
    retryCardRequest,
    deleteDocTokenSub
  } = subscriptionCreators;

  return {
    dispatchDetails: (currentUser) => dispatch(requestDetails(currentUser)),
    dispatchResetKeys: () => dispatch(resetKeys()),
    dispatchApplyCoupon: (couponCode, plans, userProfile) => dispatch(applyCoupon(couponCode, plans, userProfile)),
    dispatchRemoveCouponFromPlan: (planData) => dispatch(resetCoupon(planData)),
    dispatchChangeDefaultCard: (cardId, customerId) => dispatch(changeDefaultCard(cardId, customerId)),
    dispatchAddCardRequest: (newCardDetails, customerId) => dispatch(addCardRequest(newCardDetails, customerId)),
    dispatchDeleteCard: (cardId, customerId) => dispatch(deleteCardRequest(cardId, customerId)),
    dispatchRetryCard: (cardId, customerId, alert) => dispatch(retryCardRequest(cardId, customerId, alert)),
    dispatchDeleteDocTokenSub: (docId) => dispatch(deleteDocTokenSub(docId))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  injectIntl,
  withConnect,
  memo,
  injectSaga({ key: 'subscription', saga: subscriptionSaga })
)(Subscription);
