import React from 'react';
import { BarContainer } from './ShareBar.styles';
import { IconWithText } from '@app/modules/common/index';
import like from '../../../../images/icons/like.svg';
import share from '../../../../images/icons/share.svg';
import join from '../../../../images/icons/join.svg';

function ShareBar({ onClick }) {
  return (
    <BarContainer>
      <IconWithText icon={like} id="FAVOURITE" onClick={onClick} />
      <IconWithText icon={join} id="PLAY" onClick={onClick} />
      <IconWithText icon={share} id="SHARE" onClick={onClick} />
    </BarContainer>
  );
}

export default ShareBar;
