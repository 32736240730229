import React from 'react';
import { StyledTitleContainer, Row, Title, Detail, Description } from './Details.styles';

function Details({ teacherDetails }) {
  const { title, aboutMe, languages, experienceDescription, disciplines, experience, genderType } = teacherDetails;

  return (
    <StyledTitleContainer>
      <Title text={title} />
      <Row>
        <Detail text="Gender" />
        <Detail text={`:  ${genderType}`} />
      </Row>
      <Row>
        <Detail text="Teaching Since" />
        <Detail text={`:  ${experience}`} />
      </Row>
      <Row>
        <Detail text="Categories" />
        <Detail text={`:  ${disciplines?.join(', ')}`} />
      </Row>
      <Row>
        <Detail text="Languages" />
        <Detail text={`:  ${languages?.join(', ')}`} />
      </Row>
      <Detail text="About:" />
      <Description text={aboutMe} />
      <Description text={experienceDescription} />
    </StyledTitleContainer>
  );
}

export default Details;
