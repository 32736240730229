import React, { useEffect, useState } from 'react';
import { logToCrashalytics, getTeacherDetails } from 'tatvarnsdk/Web';
import { useHistory } from 'react-router-dom';
import {
  ClassDetailsContainer,
  NotFount,
  Button,
  NotFoundContainer,
  NotFoundImage,
  Loader,
  Border,
  ClassImage
} from './TeacherDetails.styles';
import Details from './components/Details/Details';
import Header from './components/Header/Header';
import ShareBar from './components/ShareBar/ShareBar';
import notfound from '../../images/notfound.svg';
import { routeConfig } from '../../routeConfig';
import { appleAppStoreURL, googlePlayStoreURL } from '../common/utils/constants';

function VideoDetails(props) {
  const [noVideo, setNoVideo] = useState(false);
  const [teacherDetails, setTeacherDetails] = useState(null);
  const history = useHistory();
  const [os, setOs] = useState('Unknown Device');
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || window.opera;
    if (/android/i.test(userAgent)) {
      setOs('ANDROID');
      return;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setOs('IOS');
      return;
    }
    return;
  };

  useEffect(async () => {
    logToCrashalytics('TeacherDetails');
    getMobileOperatingSystem();
    const { location } = props;
    let videoId = null;
    if (location && location.search.split('=').length > 0) {
      videoId = location.search.split('=')[1];
    }
    const response = await getTeacherDetails(videoId);
    if (response.success) {
      setTeacherDetails(response?.data);
    } else {
      setNoVideo(true);
    }
  }, []);

  useEffect(() => {
    if (os != 'Unknown Device') {
      let appBaseLink = 'tatva.app://'; // same for IOS prd and android
      if (os === 'IOS') {
        if (
          window.location.host.includes('dev') ||
          window.location.host.includes('localhost') ||
          window.location.host.includes('192') //LAN
        ) {
          appBaseLink = 'tatva.app.dev://';
        } else if (window.location.host.includes('uat')) {
          appBaseLink = 'tatva.app.uat://';
        }
      } else if (os === 'ANDROID') {
        appBaseLink = 'tatva.app://';
      }
      if (location && location.search.split('=').length > 0) {
        const teacherId = location.search.split('=')[1];
        if (teacherId) {
          const endPoint = `teacherDetails?id=${teacherId}`;
          window.location.replace(`${appBaseLink}${endPoint}`);
        }
      }
    }
  }, [os]);

  const redirectToDownload = async () => {
    history.push(routeConfig.download.route);
  };

  return (
    <ClassDetailsContainer>
      {noVideo ? (
        <NotFoundContainer>
          <NotFoundImage src={notfound} />
          <NotFount id="NO_TEACHER" />
        </NotFoundContainer>
      ) : teacherDetails ? (
        <Border>
          <Header text={teacherDetails.displayName ?? teacherDetails.name} />
          <ClassImage src={teacherDetails?.profilePicUrl} />
          <ShareBar onClick={redirectToDownload} />
          <Details teacherDetails={teacherDetails} />
          <Button textId="VIEW_CAP" onClick={redirectToDownload} />
        </Border>
      ) : (
        <Loader />
      )}
    </ClassDetailsContainer>
  );
}

export default VideoDetails;
