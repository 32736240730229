import styled from 'styled-components';
import { media } from '@app/themes/index';
import Translation from '@app/modules/common/components/Translation/Translation';

export const CouponDiv = styled.div`
  width: 50%;
  margin: auto;
  background-color: ${(props) => props.theme.black};
  color: ${(props) => props.theme.white};
  font-size: 1.125rem;
  font-weight: 700;
  ${media.lessThan('tablet')`
    width: 95%;
  `}
`;

export const BtnDiv = styled.div`
  position: absolute;
  top: 0.52rem;
  right: 0.3rem;
`;

export const CouponInputDiv = styled.div`
  position: relative;
  margin: 1rem 0;
  input {
    width: 100%;
    height: 3.5rem;
    padding: 0 2rem;
    background: ${(props) => props.theme.black};
    border: 1px solid ${(props) => props.theme.white};
    border-radius: 0.5rem;
    color: ${(props) => props.theme.white};
    font-size: 0.9375rem;
    font-weight: 400;
  }
`;

export const ErrorDiv = styled.div`
  color: ${(props) => props.theme.error};
  font-size: 1rem;
`;

export const AllCouponsContainer = styled.div``;

export const AvailableCouponDiv = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) => props.theme.veryLightGreyForBg};
  border: 1px solid ${(props) => props.theme.transparent13};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  padding: 1rem 0.3rem 1rem 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
`;

export const PromoCode = styled(Translation)`
  font-size: 1.1rem;
  font-weight: 'bold';
  align-self: center;
  margin-left: 0.5rem;
`;

export const DiscountText = styled(Translation)`
  align-self: flex-start;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  justify-content: space-between;
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
