import styled from 'styled-components';
import { StyledContainer, PrimaryButton, Translation, Loading } from '../common/index';

export const ClassDetailsContainer = styled(StyledContainer)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 430px;
  align-self: center;
  overflow: hidden;
`;

export const Border = styled.div`
  border: 0.1px solid ${(props) => props.theme.SilverChalice};
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  border-radius: 8px;
`;

export const Button = styled(PrimaryButton)`
  width: 90%;
  align-self: center;
  margin-top: 1rem;
  font-size: 1.5rem;
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
`;

export const NotFount = styled(Translation)`
  align-self: center;
  font-size: 4rem;
  text-align: center;
  color: ${(props) => props.theme.white};
`;

export const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 15%;
  align-self: center;
`;

export const NotFoundImage = styled.img`
  height: auto;
  width: 90%;
`;

export const Loader = styled(Loading)`
  margin-top: 2rem;
  align-self: center;
`;

export const ClassImage = styled.img`
  height: 'auto';
  width: 100%;
  object-fit: cover;
  margin-top: 1rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`;
