import React, { memo, useRef, useEffect } from 'react';
import { PrimaryButton } from '@app/modules/common/components/PrimaryButton/PrimaryButton';
import Translation from '@app/modules/common/components/Translation/Translation';
import { subscriptionCreators } from '@app/modules/Subscription/reducer';
import { connect } from 'react-redux';
import subscriptionSaga from '@app/modules/Subscription/saga';
import { injectIntl } from 'react-intl';
import { injectSaga } from 'redux-injectors';
import {
  selectCoupon,
  selectPlans,
  selectUserProfile,
  selectCouponError,
  selectCouponLoading,
  selectAvailableCoupons,
  selectAvailableCouponLoading
} from '@app/modules/Subscription/selectors';
import { LoadingContainer } from '../Payment/Payment.styles';
import { Loading } from '@modules/common/index';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
  CouponDiv,
  BtnDiv,
  ErrorDiv,
  CouponInputDiv,
  AllCouponsContainer,
  AvailableCouponDiv,
  PromoCode,
  DiscountText,
  Row,
  TextDiv
} from './couponVidw.styles';

function CouponView(props) {
  const {
    coupon,
    couponError,
    dispatchRemoveCouponFromPlan,
    planData,
    userProfile,
    dispatchApplyCoupon,
    couponLoading,
    availableCouponLoading,
    availableCoupons,
    getAvailableCoupon
  } = props;
  const couponInputRef = useRef();
  useEffect(() => {
    getAvailableCoupon();
  }, []);

  const handleAvailableCoupon = (promocode) => {
    if (couponInputRef.current?.value === promocode) {
      dispatchRemoveCouponFromPlan(planData);
      couponInputRef.current.value = '';
      return;
    }
    couponInputRef.current.value = promocode;
    handleCoupon(true);
  };

  const handleCoupon = (apply) => {
    if (coupon && apply !== true) {
      dispatchRemoveCouponFromPlan(planData);
      couponInputRef.current.value = '';
      return;
    }
    if (!couponInputRef.current.value) {
      return;
    }
    dispatchApplyCoupon(couponInputRef.current.value, planData, userProfile);
  };
  const paymentScreen = window.location.pathname.includes('payment'); // true if this component is in payment screen
  return (
    <CouponDiv>
      <p>
        <Translation id="APPLY_PROMOTION_CODE" />
      </p>
      {(couponLoading && paymentScreen) || availableCouponLoading ? (
        <LoadingContainer>
          <div>
            <Loading />
            <p>
              <Translation id="RELOAD_PAGE_WARNING" />
            </p>
          </div>
        </LoadingContainer>
      ) : (
        <CouponInputDiv>
          {coupon ? (
            <input type="text" ref={couponInputRef} value={coupon.code} disabled={coupon ? true : false} />
          ) : (
            <input type="text" ref={couponInputRef} disabled={coupon ? true : false} />
          )}
          <BtnDiv onClick={handleCoupon}>
            <PrimaryButton textId={coupon ? 'remove' : 'apply'} />
          </BtnDiv>
        </CouponInputDiv>
      )}
      <ErrorDiv>{couponError ? <Translation text="Invalid Coupon Code" /> : ''}</ErrorDiv>
      {(availableCoupons || []).length > 0 && (
        <AllCouponsContainer>
          <Translation id="AVAILABLE_COUPONE" />
          {(availableCoupons || []).map((code) => {
            const metadata = code.metadata;
            const thisCoupon = code.coupon;
            const message = code.message;
            const { percent_off, amount_off } = thisCoupon;
            const offText = amount_off ? `£${amount_off}` : `${percent_off}%`;
            return (
              <AvailableCouponDiv key={code.code}>
                <TextDiv>
                  <Row>
                    <Translation id="PROMO_CODE" />
                    <PromoCode text={`"${code.code}"`} />
                  </Row>
                  <DiscountText text={`Discount: ${offText}`} />
                  {metadata?.product && <DiscountText text={`Valid Only With: ${metadata?.product}`} />}
                  <DiscountText text={message} />
                </TextDiv>
                <PrimaryButton
                  onClick={() => handleAvailableCoupon(code.code)}
                  textId={code.code === coupon?.code ? 'remove' : 'apply'}
                />
              </AvailableCouponDiv>
            );
          })}
        </AllCouponsContainer>
      )}
    </CouponDiv>
  );
}

const mapStateToProps = createStructuredSelector({
  planData: selectPlans(),
  coupon: selectCoupon(),
  couponLoading: selectCouponLoading(),
  couponError: selectCouponError(),
  userProfile: selectUserProfile(),
  availableCoupons: selectAvailableCoupons(),
  availableCouponLoading: selectAvailableCouponLoading()
});

export function mapDispatchToProps(dispatch) {
  const { applyCoupon, resetCoupon, getAvailableCoupon } = subscriptionCreators;

  return {
    dispatchApplyCoupon: (couponCode, plans, userProfile) => dispatch(applyCoupon(couponCode, plans, userProfile)),
    dispatchRemoveCouponFromPlan: (planData) => dispatch(resetCoupon(planData)),
    getAvailableCoupon: () => dispatch(getAvailableCoupon())
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  injectIntl,
  withConnect,
  memo,
  injectSaga({ key: 'subscription', saga: subscriptionSaga })
)(CouponView);
