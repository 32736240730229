import React from 'react';
import { StyledTitleContainer, Row, Title, Detail, Description, RowJustify } from './Details.styles';

function Details({ videoDetails }) {
  const { title, videoDurationInSeconds, level, description } = videoDetails;
  const getDuration = () => {
    let duration = '';
    if (videoDurationInSeconds > 3600) {
      const hours = Math.floor(videoDurationInSeconds / 3600);
      const minutes = Math.floor((videoDurationInSeconds - hours * 3600) / 60);
      const seconds = Math.floor(videoDurationInSeconds - hours * 3600 - minutes * 60);
      duration = ` : ${hours}: ${minutes}:${seconds}`;
    } else if (videoDurationInSeconds > 60) {
      const minutes = Math.floor(videoDurationInSeconds / 60);
      const seconds = Math.floor(videoDurationInSeconds - minutes * 60);
      duration = ` : ${minutes}:${seconds}`;
    } else if (videoDurationInSeconds > 0) {
      duration = ` : 00:${Math.floor(videoDurationInSeconds)}`;
    }
    return duration;
  };

  return (
    <StyledTitleContainer>
      <Title text={title} />
      <RowJustify>
        <Row>
          <Detail id="DURATION" />
          <Detail text={getDuration()} />
        </Row>
        <Row>
          <Detail id="LEVEL" />
          <Detail text={`:  ${level}`} />
        </Row>
      </RowJustify>
      <Description text={description} />
    </StyledTitleContainer>
  );
}

export default Details;
