import React from 'react';
import { StyledTitleContainer, Row, Title, Detail, Description } from './Details.styles';
import { LEVELS } from '../../../../utils/constants';

function Details({ classDetails }) {
  const { title, averageDuration, level, description } = classDetails;
  return (
    <StyledTitleContainer>
      <Title text={title} />
      <Row>
        <Detail id="AVERAGE_DURATION" />
        <Detail text={`:  ${averageDuration} min`} />
      </Row>
      <Row>
        <Detail id="LEVEL" />
        <Detail text={`:  ${level}`} />
      </Row>
      <Description text={description} />
    </StyledTitleContainer>
  );
}

export default Details;
